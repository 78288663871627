<script setup lang="ts">
import { storeToRefs } from 'pinia'

import AccountDisplay from './accountSwapper/AccountDisplay.vue'

import { BtnGhost } from '~/components/ui/Buttons'
import VText from '~/components/ui/VText.vue'

import { useLoginStore } from '~/stores/login'
import { useRootStore } from '~/stores/root'

import type { PropConfigType } from '~/components/ui/VText.vue'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const textConfig: Readonly<PropConfigType> = 'sans/14/medium'

const { accounts: ACCOUNTS } = storeToRefs(useRootStore())
const { REQUEST_ACCOUNT_SWAP: LOGIN_REQUEST_ACCOUNT_SWAP } = useLoginStore()
</script>

<template>
  <div class="tw-grid tw-bg-gray-100 md:tw-bg-white">
    <div
      class="tw-flex tw-cursor-pointer tw-items-center tw-justify-start tw-gap-2 tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-px-5 tw-py-6 sm:tw-bg-white sm:tw-shadow-stickyTop"
      @click.stop="emit('close')"
    >
      <i class="fas fa-chevron-left tw-mr-auto" />
      <VText
        v-once
        :cfg="textConfig"
        color="black"
        class="tw-mr-auto tw-inline-block"
        >{{ $t('layout.menu.menu04') }}</VText
      >
    </div>
    <ul
      class="accountSwapperWrap tw-overflow-y-auto tw-px-5 tw-py-2 sm:tw-px-0"
    >
      <AccountDisplay
        v-for="account in ACCOUNTS"
        :key="account.pk"
        :account="account"
      />
    </ul>
    <div
      class="tw-mt-auto tw-flex tw-w-full tw-items-center tw-justify-center tw-bg-white tw-p-4 tw-shadow-stickyBottom"
    >
      <BtnGhost @click="LOGIN_REQUEST_ACCOUNT_SWAP()">
        {{ $t('auth.accountSwitch00') }}
      </BtnGhost>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.accountSwapperWrap {
  @screen sm {
    max-height: calc(65vh - var(--navbarHeight) - (theme('space.6') * 4));
  }
}
</style>
