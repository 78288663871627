<script setup lang="ts">
import { storeToRefs } from 'pinia'

import MismatchModalContent from '~/components/draft/popup/mismatchModalContent.vue'
import SelectionMismatchModalContent from '~/components/draft/popup/selectionMismatchModalContent.vue'
import Modal from '~/components/shared/modal.vue'

import { useMiscDraftStore } from '~/stores/miscDraft'

const { mismatchModals: MISMATCH_MODALS } = storeToRefs(useMiscDraftStore())

const { SET_MISMATCH_MODAL, SET_SELECTION_MISMATCH_MODAL } = useMiscDraftStore()

const mismatchModalContentRef = ref<InstanceType<
  typeof MismatchModalContent
> | null>(null)
const selectionMismatchModalContentRef = ref<InstanceType<
  typeof SelectionMismatchModalContent
> | null>(null)

const MISMATCH_MODAL_CONFIG = computed(
  () => MISMATCH_MODALS.value.mismatchModal,
)
const SELECTION_MISMATCH_MODAL_CONFIG = computed(
  () => MISMATCH_MODALS.value.selectionMismatchModal,
)

function closeMismatchModal({ addInfluencer = false } = {}) {
  if (addInfluencer) {
    mismatchModalContentRef.value?.handleNext({
      noClose: true,
      fromClickOutside: true,
    })
  }

  SET_MISMATCH_MODAL({ isDisplayed: false })
}

function closeSelectionMismatchModal(
  { addInfluencers } = { addInfluencers: false },
) {
  if (addInfluencers) {
    selectionMismatchModalContentRef.value?.handleNext({
      noClose: true,
      fromClickOutside: true,
    })
  }

  SET_SELECTION_MISMATCH_MODAL({ isDisplayed: false })
}
</script>

<template>
  <div>
    <Modal
      v-model="MISMATCH_MODAL_CONFIG.isDisplayed"
      bottom-filler
      :title="
        $t('components.draft.popup.mismatchModals.mismatchModal.title', {
          influencerName: MISMATCH_MODAL_CONFIG.influencer.entity,
        })
      "
      @close="closeMismatchModal({ addInfluencer: true })"
    >
      <MismatchModalContent
        ref="mismatchModalContentRef"
        :mismatch-modal-config="MISMATCH_MODAL_CONFIG"
        @close="closeMismatchModal"
      />
    </Modal>
    <Modal
      v-model="SELECTION_MISMATCH_MODAL_CONFIG.isDisplayed"
      bottom-filler
      :title="
        $t('components.draft.popup.mismatchModals.selectionMismatchModal.title')
      "
      @close="closeSelectionMismatchModal({ addInfluencers: true })"
    >
      <SelectionMismatchModalContent
        ref="selectionMismatchModalContentRef"
        :config="SELECTION_MISMATCH_MODAL_CONFIG"
        @close="closeSelectionMismatchModal"
      />
    </Modal>
  </div>
</template>
