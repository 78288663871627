import type { AuthToken } from '~/types/Auth'
import type { AuthFetch } from '~/types/globalDeclarations/AuthFetch'

/**
 * Will swap to the target jwt user. Need to be logged in to perform this action.
 *
 * @param { AuthFetch } $authFetch - The transporter.
 * @returns { Function } The api call function.
 */
export function provideSwapAccountWithJwt($authFetch: AuthFetch) {
  return function (token: string) {
    return $authFetch.$post<AuthToken>('/api/user/swap/', {
      token,
    })
  }
}
