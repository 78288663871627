import { configToQs } from '~/helpers/configToQs'

import type { CoreFetch } from '~/types/globalDeclarations/CoreFetch'
import type { UserKind } from '~/types/UserKind'

export function provideNotificationGetUnpeekedCount(coreFetch: CoreFetch) {
  return <T extends UserKind>(
    bandId?: T extends 'band' ? number : undefined,
  ): Promise<{ count: number }> => {
    return coreFetch.$get(
      `/notification/notification/unseen-count/?${configToQs({
        band_id: bandId,
      })}`,
    )
  }
}
