<template>
  <div>
    <template v-if="showTodayList">
      <VText cfg="sans/14/medium" class="tw-mx-2 tw-mb-2" html-tag="h3">{{
        $t('notifications.timing.today')
      }}</VText>
      <ul class="tw-grid tw-grid-cols-1 tw-gap-y-2">
        <ListItem
          v-for="notif in groupedNotifs.today"
          :key="notif.id"
          :item="notif"
          @read-notification="$emit('read-notification', $event)"
        />
      </ul>
    </template>

    <hr
      v-if="showTodayList && showBeforeList"
      class="tw-mb-4 tw-mt-2 tw-border-gray-200"
    />

    <template v-if="showBeforeList">
      <VText cfg="sans/14/medium" class="tw-mx-2 tw-mb-2" html-tag="h3">{{
        $t('notifications.timing.previously')
      }}</VText>
      <ul class="tw-grid tw-grid-cols-1 tw-gap-y-2">
        <ListItem
          v-for="notif in groupedNotifs.before"
          :key="notif.id"
          :item="notif"
          @read-notification="$emit('read-notification', $event)"
        />
      </ul>
    </template>
  </div>
</template>

<script lang="ts">
import isToday from 'date-fns/isToday'
import { defineComponent } from 'vue'

import ListItem from './NotificationsMenuListItem.vue'

import VText from '~/components/ui/VText.vue'

import type { Notification } from '~/types/notification-v2'
import type { PropType } from 'vue'

const putNotificationInBucket = (
  buckets: {
    today: Notification[]
    before: Notification[]
  },
  notification: Notification,
) => {
  const collection = isToday(notification.dateSent)
    ? buckets.today
    : buckets.before
  collection.push(notification)
  return buckets
}

export default defineComponent({
  components: { ListItem, VText },
  props: {
    items: {
      type: Array as PropType<Notification[]>,
      required: true,
    },
  },
  emits: ['read-notification'],
  computed: {
    groupedNotifs(): {
      today: Notification[]
      before: Notification[]
    } {
      return this.items.reduce(putNotificationInBucket, {
        today: [] as Notification[],
        before: [] as Notification[],
      })
    },
    showTodayList(): boolean {
      return this.groupedNotifs.today.length > 0
    },
    showBeforeList(): boolean {
      return this.groupedNotifs.before.length > 0
    },
  },
})
</script>
