<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { vClickOutside } from '~/directives/ClickOutside'

import Burger from '~/components/nav/mobile/burger.vue'
import NavMobileList from '~/components/nav/mobile/List.vue'
import NavMobileLoggedOut from '~/components/nav/mobile/loggedOut.vue'
import NotificationBellIcon from '~/components/notifications-v2/bellIcon.vue'
import HeightExpand from '~/components/transitions/heightExpand.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'

import { useMiscMenuStore } from '~/stores/miscMenu'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useUserStore } from '~/stores/user'

import { Breakpoints } from '~/enums/breakpoints'

import type { BellIconComponentType } from '~/components/notifications-v2/bellIcon.vue'

const segmentTrack = useSegmentTrack()

const bellIcon = ref<null | BellIconComponentType>(null)

const displayProfileMenu = ref(false)

const {
  IS_LOGGED_IN,
  IS_BAND,
  HAS_PAID: USER_HAS_PAID,
} = storeToRefs(useUserStore())
const { SET: SET_MENU_FULL_SCREEN, RESET: RESET_MENU } = useMiscMenuStore()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
watch(SCREEN_WIDTH, () => {
  if (SCREEN_WIDTH.value >= Breakpoints.sm) RESET_MENU()
})

function trackArtistReferral() {
  segmentTrack('Referral Artist - Page Accessed', {
    accessed_from: 'top navbar',
  })
}

function closeMenus() {
  closeProfileMenu()
  bellIcon.value?.closeNotificationsMenu()
}

function toggleProfileMenu() {
  const wantToOpen = !displayProfileMenu.value
  if (wantToOpen) closeMenus()

  displayProfileMenu.value = !displayProfileMenu.value
  updateProfileMenuSize()
}

function closeProfileMenu() {
  displayProfileMenu.value = false
  updateProfileMenuSize()
}

function updateProfileMenuSize() {
  if (SCREEN_WIDTH.value < Breakpoints.sm) {
    if (displayProfileMenu.value) SET_MENU_FULL_SCREEN()
    else RESET_MENU()
  }
}
</script>

<template>
  <div class="tw-flex tw-items-center tw-justify-end tw-gap-4 md:tw-gap-6">
    <NuxtLinkLocale
      v-if="IS_BAND && USER_HAS_PAID"
      to="/band/referral/"
      @click="trackArtistReferral"
    >
      <i
        class="referralIcon fas fa-gift tw-cursor-pointer !tw-text-xl tw-text-orange-700"
      />
    </NuxtLinkLocale>

    <NotificationBellIcon ref="bellIcon" />

    <div v-click-outside="closeProfileMenu">
      <Burger :open="displayProfileMenu" @toggle="toggleProfileMenu" />
      <HeightExpand>
        <div
          v-if="displayProfileMenu"
          class="contextMenu tw-absolute tw-right-0 tw-top-14 tw-z-50 tw-w-full tw-overflow-y-auto tw-bg-gray-100"
          :class="{ notLogged: !IS_LOGGED_IN }"
        >
          <NavMobileList
            v-if="IS_LOGGED_IN"
            class="listingWrapper"
            @close="closeProfileMenu"
          />
          <NavMobileLoggedOut
            v-else
            class="listingWrapper"
            @close="closeProfileMenu"
          />
        </div>
      </HeightExpand>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contextMenu {
  box-shadow:
    inset 0px 6px 8px -8px rgba(0, 0, 0, 0.05),
    0px 6px 12px -5px rgba(51, 51, 51, 0.05);
  .listingWrapper {
    height: calc(100vh - var(--navbarHeight));

    @screen sm {
      @apply tw-h-auto;
    }
  }

  @screen sm {
    @apply tw-right-8 tw-h-min tw-w-[320px] tw-bg-white tw-shadow-hoverDropdown;

    &.notLogged {
      @apply tw-px-5 tw-pb-5;
    }
  }
}

.referralIcon {
  @keyframes wiggleWaggle {
    from {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: 100ms ease-in-out 4 1500ms reverse both running wiggleWaggle;
  }
}
</style>
