<template>
  <div class="tw-flex tw-h-4 tw-items-center tw-justify-center tw-space-x-1">
    <div
      v-for="index in 3"
      :key="index"
      class="dot tw-h-2 tw-w-2 tw-rounded-full tw-bg-gray-500"
      :class="`dot_${index}`"
    />
  </div>
</template>

<style lang="scss" scoped>
.dot {
  @media (prefers-reduced-motion: no-preference) {
    animation: dot-jump 0.3s forwards infinite alternate;
  }
}

.dot_1 {
  animation-delay: 0s;
}

.dot_2 {
  animation-delay: 0.1s;
}

.dot_3 {
  animation-delay: 0.2s;
}

@keyframes dot-jump {
  0% {
    transform: translateY(0);
  }

  100% {
    @apply tw-bg-gray-300;
    transform: translateY(-4px);
  }
}
</style>
