<template>
  <NuxtLink
    class="tw-flex tw-cursor-pointer tw-items-center"
    :to="switchToLocalePath"
    @click="switchLang(nextTargetLang)"
  >
    <i class="fas fa-globe-europe tw-mr-1 tw-text-gray-500" />
    <DirectionalSwipe vertical :direction="animationDirection">
      <Sm500Black :key="LANG" class="tw-uppercase">{{ LANG }}</Sm500Black>
    </DirectionalSwipe>
  </NuxtLink>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import DirectionalSwipe from '~/components/transitions/directionalSwipe.vue'
import { Sm500Black } from '~/components/typography'

import patchLangMixin from '~/mixins/band/patchLang'
import eventsMixin from '~/mixins/events'

import { useUserStore } from '~/stores/user'

import type Lang from '~/types/lang'

export default defineComponent({
  components: {
    Sm500Black,
    DirectionalSwipe,
  },
  mixins: [patchLangMixin, eventsMixin],
  computed: {
    ...mapState(useUserStore, {
      IS_LOGGED_IN: 'IS_LOGGED_IN',
      USER_EMAIL: 'email',
    }),
    // ...mapGetters({
    //   IS_LOGGED_IN: 'user/IS_LOGGED_IN',
    // }),
    // ...mapState({
    //   USER_EMAIL: (state: any) => state.user.email,
    // }),
    nextTargetLang(): Lang {
      if (this.LANG === 'en') return 'fr'
      else return 'en'
    },
    animationDirection() {
      return this.LANG === 'fr' ? 'up' : 'down'
    },
    LANG(): Lang {
      return this.$i18n.locale as Lang
    },
    switchToLocalePath() {
      const switchLocalePath = useSwitchLocalePath()
      return switchLocalePath(this.nextTargetLang)
    },
  },
  methods: {
    switchLang(targetLang: Lang) {
      const origin_lang = this.LANG

      if (this.IS_LOGGED_IN) this.patchLang(targetLang, this.USER_EMAIL, false)
      else this.updateStoreSideLang(targetLang)

      this.trackEvent({
        category: 'i18n',
        action: 'Toggle',
        target_lang: targetLang,
        origin_lang,
      })
    },
  },
})
</script>
