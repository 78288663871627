import { acceptHMRUpdate, defineStore } from 'pinia'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

const initialState = function () {
  return {
    fullScreen: false,
  }
}

const state = initialState

export type MiscMenuState = ReturnType<typeof state>

export const useMiscMenuStore = defineStore('miscMenu', {
  state: (): MiscMenuState => ({ ...state() }),
  actions: {
    SET() {
      this.fullScreen = true
    },
    RESET() {
      resetStoreToInitialState.bind(this)(initialState())
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMiscMenuStore, import.meta.hot))
