<script setup lang="ts">
import { storeToRefs } from 'pinia'

import BandPill from './bandPill.vue'

import { useUserBandStore } from '~/stores/userBand'
import { useUserBandSetStore } from '~/stores/userBandSet'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const { id: currentBand } = storeToRefs(useUserBandStore())
const { LIVE_BANDS: bands } = storeToRefs(useUserBandSetStore())

const bandSet = computed(() =>
  bands.value.filter((band) => band.id !== currentBand.value),
)
const bandSetLength = computed(() => bands.value.length)
</script>

<template>
  <div class="ArtistRepresentativeMenuWrapper">
    <span class="title">{{ $t('band.RPMenu.subtitle') }}</span>
    <div v-if="bandSetLength > 1">
      <div class="pictures">
        <BandPill
          v-for="band in bandSet.slice(0, 4)"
          :key="band.id"
          :band="band"
        />
      </div>
    </div>
    <div class="bottomContent">
      <span class="count"
        >{{ bandSetLength }}
        {{ $t('band.RPMenu.count', bandSetLength) }} ·</span
      >
      <NuxtLinkLocale to="/band/profile/select/" @click="$emit('close')">
        <span data-test-id="rPArtistsProfilesCTA" class="all">{{
          $t('band.RPMenu.all')
        }}</span>
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<style scoped>
.ArtistRepresentativeMenuWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--color-black);
  font-size: var(--font-size-big);
  font-weight: 500;
  
}

.subtitle {
  font-size: var(--font-size-small);
  color: var(--color-storm-grey);
}

.pictures {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
}

.bottomContent {
  display: flex;
  align-items: center;
  margin-left: 0;
}
.count {
  color: var(--color-darker-grey);
  font-size: var(--font-size-small);
  margin-right: 2px;
}

.all {
  font-size: var(--font-size-small);
  color: var(--color-main);
  font-weight: 500;
  cursor: pointer;
}
</style>
