<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { useRootStore } from '~/stores/root'
import { useUserStore } from '~/stores/user'

import { hasSlotContent } from '~/utils/hasSlot'

type Emits = {
  'display-account-swapper': []
  'request-account-swap': []
  logout: []
}
type Slots = {
  injectElemAfterAccounts(): any
}

const emit = defineEmits<Emits>()
defineSlots<Slots>()

const { accounts: ACCOUNTS } = storeToRefs(useRootStore())
const { KIND: USER_KIND } = storeToRefs(useUserStore())
</script>

<template>
  <div class="navContextMenuSharedWrapper">
    <NuxtLinkLocale
      :to="`/${USER_KIND}/parameter/`"
      class="navBaseElem paddedElem"
      data-test-id="profileDropdownSettingsCTA"
    >
      <span class="text">{{ $t('layout.menu.menu02') }}</span>
    </NuxtLinkLocale>
    <a
      href="https://help.groover.co/"
      class="navBaseElem paddedElem"
      target="_blank"
      data-test-id="profileDropdownHelpCTA"
    >
      <span class="text">{{ $t('layout.menu.menu03') }}</span>
    </a>
    <div
      v-if="ACCOUNTS.length"
      class="navBaseElem paddedElem"
      @click.stop="emit('display-account-swapper')"
    >
      <div class="flexWrap">
        <span class="text">{{ $t('layout.menu.menu04') }}</span>
        <i class="icon fas fa-chevron-right" />
      </div>
    </div>
    <div
      v-else
      class="navBaseElem paddedElem"
      data-test-id="profileDropdownAddExistingAccountCTA"
      @click="emit('request-account-swap')"
    >
      <span class="text">{{ $t('auth.accountSwitch00') }}</span>
    </div>
    <div
      v-if="hasSlotContent($slots['injectElemAfterAccounts'])"
      class="navBaseElem paddedElem"
    >
      <slot name="injectElemAfterAccounts" />
    </div>
    <div
      class="navBaseElem paddedElem logout"
      data-test-id="profileDropdownLogoutCTA"
      @click="emit('logout')"
    >
      <span class="text">{{ $t('layout.menu.menu05') }}</span>
    </div>
  </div>
</template>

<!-- TODO these styles should be abstracted to functional components -->
<style scoped src="~/components/nav/loggedIn/navBaseElem.css"></style>
<style scoped lang="scss">
.text,
.navBaseElem.paddedElem :deep(.text) {
  @apply tw-text-sm tw-font-medium tw-text-black;
}

.icon {
  @apply tw-text-xs tw-text-gray-500;
}

.flexWrap {
  @apply tw-relative tw-flex tw-w-full tw-items-center tw-justify-between;
}

.navBaseElem.logout {
  @apply tw-mb-2;
}
</style>
