<script setup lang="ts">
import { useLoginStore } from '~/stores/login'
import { useRootStore } from '~/stores/root'

import { cleanCacheAll } from '~/helpers/CleanCache'
import {
  backupAccountSecret,
  retrieveAccountSecretFromUserId,
} from '~/helpers/SwapAccount'

import { provideSwapAccountWithJwt } from '~/api-auth/Swap'
import {
  provideDeleteFromCurrentUserFamily,
  provideGetTargetAccountInformation,
} from '~/api-core/Family/index'
import {
  provideLoginSession,
  provideLogoutSession,
} from '~/api-core/Login/Session'

import type { ExistingUser } from '~/stores/login'
import type UserAccount from '~/types/userAccount'

type Props = {
  account: UserAccount
}

const props = defineProps<Props>()

const { t } = useI18n()

const { REQUEST_ACCOUNT_SWAP } = useLoginStore()
const { REMOVE_ACCOUNT_BY_ID } = useRootStore()

const loading = ref(false)

const name = computed<string>(
  () =>
    props.account.first_name ||
    props.account.email ||
    props.account.name ||
    'No Name',
)
const kind = computed<string>(() => {
  switch (props.account.kind) {
    case 'agency':
      return t('common.PR')
    case 'band':
      return t('common.AR')
    case 'influencer':
      return t('common.inf')
    default:
      return ''
  }
})

async function triggerAccountSwap(): Promise<any> {
  const { pk: id } = props.account
  const getAccountInformation = provideGetTargetAccountInformation($coreFetch)
  try {
    const result = await retrieveAccountSecretFromUserId(id)

    const accountInformation = await getAccountInformation(id)
    const account: ExistingUser = {
      email: props.account.email,
      email_disabled: false,
      first_name: props.account.first_name || '',
      is_facebook: accountInformation.social_accounts.some(
        ({ provider }) => provider === 'facebook',
      ),
      is_google: accountInformation.social_accounts.some(
        ({ provider }) => provider === 'google',
      ),
      is_soundcloud: accountInformation.social_accounts.some(
        ({ provider }) => provider === 'soundcloud',
      ),
      picture: props.account.picture,
    }

    if (!result) return REQUEST_ACCOUNT_SWAP(account)

    const { access } = await provideSwapAccountWithJwt($authFetch)(result.value)
    await provideLogoutSession($coreFetch)()
    const user = await provideLoginSession($coreFetch)(access)

    backupAccountSecret(user.id, access)
    cleanCacheAll()
    window.location.href = window.location.origin + '/'
  } catch (warning) {
    REQUEST_ACCOUNT_SWAP()
  }
}

async function deleteSelf() {
  try {
    if (loading.value) return

    loading.value = true
    await provideDeleteFromCurrentUserFamily($coreFetch)(props.account.pk)
    REMOVE_ACCOUNT_BY_ID(props.account.pk)
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <div
    class="accountDisplayWrapper tw-relative tw-mt-1 tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-start tw-px-0 tw-py-3 sm:tw-px-5 sm:tw-py-3"
    @click="triggerAccountSwap"
  >
    <div class="tw-relative tw-mr-4 tw-h-8 tw-w-8 tw-flex-shrink-0">
      <img
        class="tw-h-full tw-w-full tw-overflow-hidden tw-rounded-full"
        :src="account.picture"
      />
    </div>
    <div class="content">
      <div
        class="ellipsis tw-font-sans tw-text-base tw-font-medium tw-text-black"
      >
        {{ name }}
      </div>
      <div class="ellipsis tw-text-sm tw-text-gray-500">
        {{ kind }}
      </div>
    </div>
    <div class="delete tw-ml-4 tw-hidden" @click.stop="deleteSelf">
      <i class="fas fa-times" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.accountDisplayWrapper,
.accountDisplayWrapper .content > * {
  @apply tw-transition-colors;
}

.accountDisplayWrapper:hover {
  @apply tw-bg-gray-200;
}

.content {
  @apply tw-w-full tw-overflow-hidden;
}

.delete > i {
  @apply tw-text-gray-500;
}

.accountDisplayWrapper:hover .delete {
  @apply tw-block tw-text-gray-500;
}
</style>
