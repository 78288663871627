<template>
  <div>
    <div
      class="mismatchPill tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-px-3 tw-py-2 sm:tw-px-4 sm:tw-py-3"
      :class="containerClass"
    >
      <div class="tw-flex tw-w-4 tw-justify-center">
        <i :class="iconClass" class="tw-text-xs sm:tw-text-sm" />
      </div>
      <component :is="textComponent" class="tw-w-full">{{
        mismatchText
      }}</component>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import {
  Sm600Error,
  Sm600Gray,
  Xs600Error,
  Xs600Gray,
} from '~/components/typography'

import { useMiscResizeStore } from '~/stores/miscResize'

export default defineComponent({
  components: {
    Sm600Error,
    Sm600Gray,
    Xs600Error,
    Xs600Gray,
  },
  props: {
    isDuplicate: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    mismatchText: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useMiscResizeStore, ['SCREEN_WIDTH']),
    containerClass(): string {
      return this.isError
        ? 'tw-text-error tw-bg-pink-200'
        : 'tw-text-gray-500 tw-bg-gray-200'
    },
    iconClass(): string {
      return `icon fas ${
        this.isDuplicate
          ? 'fa-exclamation-triangle'
          : this.isError
            ? 'fa-times'
            : 'fa-info'
      }`
    },
    textComponent(): string {
      if (this.SCREEN_WIDTH < 480)
        return this.isError ? 'Xs600Error' : 'Xs600Gray'
      else return this.isError ? 'Sm600Error' : 'Sm600Gray'
    },
  },
})
</script>

<style scoped lang="scss">
.mismatchPill {
  border-radius: 23px;
}
</style>
