<script setup lang="ts">
import { storeToRefs } from 'pinia'

import NavContextMenuBand from './contextMenu/band.vue'
import ProfileInfos from './contextMenu/profileInfos.vue'
import NavContextMenuShared from './contextMenu/Shared.vue'

import AccountSwapper from '~/components/nav/loggedIn/contextMenu/AccountSwapper.vue'
import DirectionalSwipe from '~/components/transitions/directionalSwipe.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useLogout } from '~/composables/useLogout'

import { useLoginStore } from '~/stores/login'
import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'
import { useUserInfluencerStore } from '~/stores/userInfluencer'

import type Band from '~/types/band'
import type { Influencer } from '~/types/influencer'
import type { SwipeDirection } from '~/types/SwipeDirection'

const { HAS_BAND_SELECTED, BAND_DATA } = storeToRefs(useUserBandStore())

const userStore = useUserStore()
const segmentTrack = useSegmentTrack()
const { logout } = useLogout()
const { IS_INF, IS_BAND, IS_AGENCY, KIND } = storeToRefs(userStore)

const { REQUEST_ACCOUNT_SWAP: LOGIN_REQUEST_ACCOUNT_SWAP } = useLoginStore()
const userInfluencerStore = useUserInfluencerStore()

const transitionDirection = ref<SwipeDirection>('right')
const displayAccountSwapper = ref(false)

const isValidUser = computed<boolean>(
  () => HAS_BAND_SELECTED.value || IS_INF.value,
)
const selectedData = computed<Band | Influencer>(
  () =>
    BAND_DATA.value || (userInfluencerStore.$state as unknown as Influencer),
)

function handleTrackReferralAccessSegment(): void {
  segmentTrack('Referral Curator - Page Accessed', {
    accessed_from: 'dropdown_menu',
  })
}
</script>

<template>
  <div class="contextMenuWrapper tw-w-full tw-rounded-t-sm tw-shadow-round">
    <DirectionalSwipe :direction="transitionDirection">
      <AccountSwapper
        v-if="displayAccountSwapper"
        key="accSwap"
        class="accSwap heightControl"
        @close="displayAccountSwapper = false"
      />
      <div v-else key="mainMenu" class="navigation heightControl">
        <ProfileInfos
          v-if="isValidUser && KIND"
          :entity="selectedData"
          :kind="KIND"
          class="tw-px-5 tw-pt-4"
        />
        <div
          v-if="!IS_AGENCY"
          class="separator tw-ml-5 tw-mr-5 tw-mt-5 tw-h-px tw-bg-gray-200"
        />
        <NavContextMenuBand />
        <NavContextMenuShared
          @request-account-swap="LOGIN_REQUEST_ACCOUNT_SWAP()"
          @display-account-swapper="displayAccountSwapper = true"
          @logout="logout()"
        >
          <template v-if="IS_INF" #injectElemAfterAccounts>
            <NuxtLinkLocale
              to="/influencer/widget/"
              @click="handleTrackReferralAccessSegment"
            >
              <span class="text tw-inline-block">{{
                $t('layout.menu.receiveMoreMusic')
              }}</span>
              <span
                class="shareLabel tw-mt-1 tw-block tw-text-xs tw-text-gray-500"
                >{{ $t('layout.menu.shareGrooverAround') }}</span
              >
            </NuxtLinkLocale>
          </template>
        </NavContextMenuShared>
      </div>
    </DirectionalSwipe>
  </div>
</template>

<style scoped src="~/components/nav/loggedIn/navBaseElem.css"></style>
<style scoped lang="scss">
a {
  @apply tw-block;
}
</style>
