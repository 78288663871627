<template>
  <div class="snackbarWrapper tw-pointer-events-auto tw-w-fit tw-max-w-lg">
    <ClientOnly>
      <LazySharedLottiePlayer
        v-if="config.props.hasConfettiAnimation && SCREEN_WIDTH > 768"
        class="tw-absolute tw--bottom-12 tw-z-0 tw-h-[350px] tw-w-full"
        auto-play
        animation-link="https://lottie.host/d4def9ad-1121-4fd5-be2a-039c25beb80b/Nl1sVlaNkx.json"
        :speed="1"
        :height="350"
      />
    </ClientOnly>
    <div
      class="snackbar tw-flex tw-items-center tw-justify-start tw-rounded-sm tw-p-4 tw-shadow-round"
      :class="config.class"
    >
      <div class="tw-relative tw-flex tw-h-full tw-w-full tw-justify-start">
        <component :is="component" v-bind="config.props" @use="handleAction" />
      </div>
      <ButtonIcon @click="destroy">
        <i
          class="fas fa-times tw-cursor-pointer tw-pl-4 tw-text-sm tw-text-white"
        />
      </ButtonIcon>
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonIcon from '~/components/ui/Buttons/icon.vue'

import { useMiscResizeStore } from '~/stores/miscResize'
// import SnackbarDefaultTemplate from '~/components/shared/snackbar/defaultTemplate.vue'
import { useSnackbarStore } from '~/stores/snackbar'

import type { SnackbarConfiguration } from '~/stores/snackbar'

const snackbarStore = useSnackbarStore()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

type Props = {
  config: SnackbarConfiguration
}

const props = defineProps<Props>()

const component = computed(() => {
  const component =
    props.config.component ||
    defineAsyncComponent(
      () => import('~/components/shared/snackbar/defaultTemplate.vue'),
    )
  return {
    ...component,
    mixins: [{ ...props.config.hooks }],
  }
  // }
})

function handleAction() {
  if (typeof props.config.callbacks.use !== 'function') return

  props.config.callbacks.use()
}

function destroy() {
  if (typeof props.config.callbacks.close === 'function')
    props.config.callbacks.close()

  snackbarStore.REMOVE({
    method: 'BY_ID',
    id: props.config.id,
  })
}
</script>

<style scoped lang="scss">
.snackbarWrapper {
  @apply tw-relative;

  @screen sm {
    @apply tw-flex tw-w-full tw-justify-center;
  }
  @screen md {
    @apply tw-w-fit tw-justify-start;
  }
}

.snackbar {
  &:not(.custom-bg) {
    @apply tw-bg-black;
  }

  @screen 375 {
    @apply tw-p-5;
  }

  &.custom-bg.animated-background-gradient {
    @apply tw-bg-pink-500;
    background: linear-gradient(90deg, #eb457c, #ff8e75);
    background-size: 400% 400%;
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: gradient 3s infinite;
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
}
</style>
