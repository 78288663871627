<script setup lang="ts">
import { storeToRefs } from 'pinia'

import BandSelect from '~/components/nav/loggedIn/contextMenu/band/bandSelect.vue'
import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'
import { useSegmentTrackCampaign } from '~/composables/useSegmentTrackCampaign'

import { useUserStore } from '~/stores/user'

import type { PropConfigType } from '~/components/ui/VText.vue'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()
const { getMediaUrl } = useGetMediaUrl()
const { trackCampaignInitiated } = useSegmentTrackCampaign()

const { grooviz: GROOVIZ, IS_AGENCY } = storeToRefs(useUserStore())

const textConfig: PropConfigType = 'sans/14/medium'
const titleConfig: PropConfigType = 'sans/16/medium'
const expand = ref(false)

const chevronFeedback = computed<'up' | 'down'>(() =>
  expand.value ? 'up' : 'down',
)

function toggle(): void {
  expand.value = !expand.value
}

function handleStartACampaignClicked(): void {
  trackCampaignInitiated('header')
  close()
}

function close(): void {
  emit('close')
}
</script>

<template>
  <div class="tw-grid tw-grid-cols-1">
    <NuxtLinkLocale to="/" class="hoverText" @click="$emit('close')">
      <VText v-once :cfg="textConfig">
        {{ $t('layout.navigation00') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      to="/draft/"
      class="hoverText"
      @click="handleStartACampaignClicked"
    >
      <VText v-once :cfg="textConfig">
        {{ $t('layout.navigation11') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      to="/band/dashboard/"
      class="hoverText"
      @click="$emit('close')"
    >
      <VText v-once :cfg="textConfig">
        {{ $t('layout.navigation04') }}
      </VText>
    </NuxtLinkLocale>
    <div v-if="IS_AGENCY" class="tw-grid tw-grid-cols-1 sm:tw-px-5">
      <div class="fakeBorder" />
      <BandSelect @close="$emit('close')" />
      <div class="fakeBorder" />
    </div>
    <VText
      v-if="IS_AGENCY"
      v-once
      :cfg="titleConfig"
      html-tag="div"
      class="tw-py-3 sm:tw-px-5"
    >
      {{ $t('band.RPMenu.account') }}
    </VText>
    <NuxtLinkLocale
      class="hoverText"
      to="/agency/favorites/"
      @click="$emit('close')"
    >
      <VText v-once :cfg="textConfig">
        {{ $t('layout.menu.menu00') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale to="/band/shop/" class="hoverText" @click="$emit('close')">
      <VText
        v-once
        :cfg="textConfig"
        class="tw-flex tw-items-center tw-justify-start tw-gap-1"
      >
        <span class="feedbackText tw-text-black">{{
          $t('layout.navigation08', GROOVIZ)
        }}</span>
        <img
          class="tw-h-4 tw-w-4 tw-overflow-hidden tw-object-contain tw-object-center"
          :src="getMediaUrl('grooviz_light_grey.svg')"
        />
      </VText>
    </NuxtLinkLocale>
  </div>
</template>

<style lang="scss" scoped>
.fakeBorder {
  @apply tw-my-3 tw-h-px tw-w-full tw-bg-gray-200;
}
</style>
