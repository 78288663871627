<template>
  <div>
    <VText cfg="sans/14/medium" class="tw-leading-[17px]" html-tag="h3">{{
      $t('notifications.empty.title')
    }}</VText>
    <VText cfg="sans/13/regular">{{
      $t(`notifications.empty.${USER_KIND}`)
    }}</VText>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import VText from '~/components/ui/VText.vue'

import { useUserStore } from '~/stores/user'

export default defineComponent({
  components: { VText },
  computed: {
    ...mapState(useUserStore, { USER_KIND: 'KIND' }),
    // ...mapGetters({
    //   USER_KIND: 'user/KIND',
    // }),
  },
})
</script>

<style lang="scss" scoped></style>
