<script setup lang="ts">
import { storeToRefs } from 'pinia'

import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useUserStore } from '~/stores/user'

const { getMediaUrl } = useGetMediaUrl()

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const { grooviz: GROOVIZ } = storeToRefs(useUserStore())
</script>

<template>
  <div class="tw-grid tw-grid-cols-1">
    <NuxtLinkLocale
      class="hoverText"
      to="/influencer/cashout/"
      @click="emit('close')"
    >
      <div class="tw-flex tw-items-center tw-justify-start tw-gap-2">
        <VText v-once cfg="sans/14/medium">
          {{ $t('layout.navigation08', GROOVIZ) }}
        </VText>
        <img
          class="tw-h-4 tw-w-4 tw-overflow-hidden tw-object-contain tw-object-center"
          :src="getMediaUrl('grooviz_light_grey.svg')"
        />
      </div>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      class="hoverText"
      to="/influencer/dashboard/"
      @click="emit('close')"
    >
      <VText v-once cfg="sans/14/medium">
        {{ $t('layout.navigation03') }}
      </VText>
    </NuxtLinkLocale>
  </div>
</template>
