<template>
  <div class="tw-px-6 tw-pt-8">
    <div class="tw-grid tw-grid-cols-1 tw-gap-6">
      <Sm500Black
        class="tw-cursor-pointer"
        @click="handleScroll('mediasAndPros')"
        >{{ $t('nav.mobile.loggedOut.ourMediasAndPros') }}</Sm500Black
      >
      <Sm500Black
        class="tw-cursor-pointer"
        @click="handleScroll('howitworks')"
        >{{ $t('nav.mobile.loggedOut.howItWorks') }}</Sm500Black
      >
      <Sm500Black
        class="tw-cursor-pointer"
        @click="handleScroll('landingPricing')"
        >{{ $t('nav.mobile.loggedOut.pricing') }}</Sm500Black
      >
      <a
        :href="blogLink"
        target="_blank"
        class="tw-cursor-pointer"
        @click="handleBlogClick"
      >
        <Sm500Black>{{ $t('nav.mobile.loggedOut.blog') }}</Sm500Black>
      </a>
      <div class="separator"></div>
      <NuxtLinkLocale to="/influencer/signup/" @click="handleBecomeAProClick">
        <Sm500Black>{{ $t('nav.mobile.loggedOut.becomeAPro') }}</Sm500Black>
      </NuxtLinkLocale>
      <Sm600Orange class="tw-cursor-pointer" @click="handleSigninClick">{{
        $t('common.signIn')
      }}</Sm600Orange>
      <BtnOrange small @click="handleSignupClick">
        <span class="btnText">{{ $t('common.signUp') }}</span>
      </BtnOrange>
      <div class="separator"></div>
      <SwitchLang class="tw-mb-4 sm:tw-mb-0" />
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'

import SwitchLang from '~/components/nav/switchLang.vue'
import { Sm500Black, Sm600Orange } from '~/components/typography'
import { BtnOrange } from '~/components/ui/Buttons/'

import eventsMixin from '~/mixins/events'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useLoginStore } from '~/stores/login'

import softScrollToDomId from '~/helpers/shared/softScrollToDomId'

export default defineComponent({
  components: {
    BtnOrange,
    Sm500Black,
    Sm600Orange,
    SwitchLang,
  },
  mixins: [eventsMixin],
  emits: ['close'],
  data() {
    return {
      blogLink: 'https://blog.groover.co',
    }
  },
  methods: {
    ...mapActions(useLoginStore, { LOGIN_SET_DISPLAY: 'SET_DISPLAY' }),
    ...mapActions(useBandSignupStore, {
      BAND_SIGNUP_SET_DISPLAY: 'SET_DISPLAY',
    }),
    scrollToDomId(id: string): void {
      this.$emit('close')
      window.setTimeout(() => {
        softScrollToDomId(id)
      }, 350)
    },
    handleScroll(domId: string): void {
      this.scrollToDomId(domId)
      this.trackEvent({
        category: 'Navbar',
        action: 'Scroll to section',
        target: domId,
      })
    },
    handleBlogClick(): void {
      this.trackEvent({
        category: 'Navbar',
        action: 'Open blog page',
        link: this.blogLink,
      })
    },
    handleBecomeAProClick(): void {
      this.$emit('close')
      this.trackEvent({
        category: 'Navbar',
        action: 'Open influencer signup',
      })
    },
    handleSigninClick(): void {
      this.LOGIN_SET_DISPLAY(true)
      this.$emit('close')
      this.trackEvent({
        category: 'Navbar',
        action: 'Open sign in dialog',
        label: 'navCTA',
      })
    },
    handleSignupClick(): void {
      this.BAND_SIGNUP_SET_DISPLAY(true)
      this.$emit('close')
      this.trackEvent({
        category: 'Navbar',
        action: 'Open sign up dialog',
        label: 'navCTA',
      })
    },
  },
})
</script>

<style scoped lang="scss">
.separator {
  @apply tw-relative tw-h-px tw-w-full tw-bg-gray-300;
}
</style>
