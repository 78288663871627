<template>
  <div
    class="noScrollbar centerWrapper tw-fixed tw-bottom-4 tw-left-4 tw-z-[1000001]"
  >
    <TransitionGroup
      v-if="QUEUE && QUEUE.length"
      :name="transitionName"
      tag="div"
      class="tw-grid tw-grid-cols-1 tw-gap-4"
      role="alert"
    >
      <Snackbar v-for="elem in QUEUE" :key="elem.id" :config="elem" />
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import Snackbar from '~/components/shared/snackbar.vue'

import { useSnackbarStore } from '~/stores/snackbar'

export default defineComponent({
  components: {
    Snackbar,
  },
  data() {
    return {
      transitionName: 'list' as const,
    }
  },
  computed: {
    ...mapState(useSnackbarStore, { QUEUE: 'queue' }),
  },
  watch: {
    'QUEUE.length'() {
      const target = this.$el

      if (!target) return

      target.scrollTo({
        top: target.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
})
</script>

<style scoped lang="scss">
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
  width: max-content;
}

.centerWrapper {
  max-height: calc(100vh - theme('space.10'));
}
</style>
