import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import { useUserInfluencerStore } from '~/stores/userInfluencer'

import {
  NudgeType,
  provideFetchCuratorProfileCompletion,
  providePatchCuratorIgnoredNudges,
} from '~/api-core/Curators/CuratorProfileCompletion'

import type {
  NudgeName,
  ProfileCompletionData,
} from '~/api-core/Curators/CuratorProfileCompletion'

/**
 * @deprecated Use composables/useCuratorProfileProgression.ts.
 */
export default defineComponent({
  data() {
    return {
      /**
       * @deprecated Use composables/useCuratorProfileProgression.ts.
       */
      profileProgressionPercentage: 0,
      /**
       * @deprecated Use composables/useCuratorProfileProgression.ts.
       */
      updateNudgeNames: [] as NudgeName[],
      /**
       * @deprecated Use composables/useCuratorProfileProgression.ts.
       */
      ignoredNudgeNames: [] as NudgeName[],
      /**
       * @deprecated Use composables/useCuratorProfileProgression.ts.
       */
      fillNudgeNames: [] as NudgeName[],
      /**
       * @deprecated Use composables/useCuratorProfileProgression.ts.
       */
      totalStepCount: 10,
      /**
       * @deprecated Use composables/useCuratorProfileProgression.ts.
       */
      doesBackendAllowToShowProfileNudges: false,
    }
  },
  computed: {
    ...mapState(useUserInfluencerStore, { INFLUENCER_ID: 'id' }),
    /**
     * @deprecated Use composables/useCuratorProfileProgression.ts.
     */
    displayedNudgesType(): NudgeType {
      return this.fillNudgeNames.length ? NudgeType.fill : NudgeType.update
    },
    /**
     * @deprecated Use composables/useCuratorProfileProgression.ts.
     */
    nudgesToDisplay(): NudgeName[] {
      return this.displayedNudgesType === NudgeType.fill
        ? this.fillNudgeNames
        : this.updateNudgeNames
    },
    /**
     * @deprecated Use composables/useCuratorProfileProgression.ts.
     */
    shouldShowProfileNudges(): boolean {
      return (
        this.doesBackendAllowToShowProfileNudges &&
        Boolean(this.nudgesToDisplay.length)
      )
    },
  },
  async mounted(): Promise<void> {
    const fetchCuratorProfileCompletion =
      provideFetchCuratorProfileCompletion($coreFetch)
    const {
      fill_section_nudges,
      ignored_nudges,
      up_to_date_nudges,
    }: ProfileCompletionData = await fetchCuratorProfileCompletion(
      this.INFLUENCER_ID,
    )
    this.profileProgressionPercentage = fill_section_nudges.score
    // N.B: the ignored nudges have already been filtered out on the backend
    this.fillNudgeNames = fill_section_nudges.nudges
    this.doesBackendAllowToShowProfileNudges = Boolean(
      fill_section_nudges.nudges.length,
    )
    this.ignoredNudgeNames = ignored_nudges
    this.updateNudgeNames = up_to_date_nudges
  },
  methods: {
    /**
     * @deprecated Use composables/useCuratorProfileProgression.ts.
     */
    handleIgnoreNudgeName(): void {
      if (this.displayedNudgesType === NudgeType.update)
        return this.ignoreProfileUpdate()

      this.ignoreProfileFill()
    },
    /**
     * @deprecated Use composables/useCuratorProfileProgression.ts.
     */
    ignoreProfileFill(): void {
      const newIgnoredNudgeName = this.fillNudgeNames.shift() as NudgeName
      this.ignoredNudgeNames.push(newIgnoredNudgeName)
      this.saveIgnoredNudges()
    },
    /**
     * @deprecated Use composables/useCuratorProfileProgression.ts.
     */
    ignoreProfileUpdate(): void {
      this.updateNudgeNames.shift()
    },
    /**
     * @deprecated Use composables/useCuratorProfileProgression.ts.
     */
    saveIgnoredNudges(): void {
      const patchCuratorIgnoredNudges =
        providePatchCuratorIgnoredNudges($coreFetch)
      patchCuratorIgnoredNudges(
        this.INFLUENCER_ID,
        this.ignoredNudgeNames,
        !this.fillNudgeNames.length,
      )
    },
  },
})
