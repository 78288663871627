<template>
  <div>
    <NuxtLinkLocale class="sharedNavigationText" to="/influencer/list/all/">
      <VText v-once :cfg="commonTextConfig">{{
        $t('layout.navigation12')
      }}</VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale class="sharedNavigationText" to="/influencer/dashboard/">
      <VText v-once :cfg="commonTextConfig">{{
        $t('layout.navigation03')
      }}</VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      class="sharedNavigationText"
      to="/influencer/widget/"
      @click="handleTrackReferralAccessSegment"
    >
      <VText :cfg="commonTextConfig">{{
        $t('components.nav.loggedInCurator.widget')
      }}</VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale class="sharedNavigationText" to="/influencer/cashout/">
      <VText class="tw-hidden lg:tw-inline-block" :cfg="commonTextConfig">{{
        $t('layout.navigation08', GROOVIZ)
      }}</VText>
      <VText
        v-once
        class="tw-inline-block lg:tw-hidden"
        :cfg="commonTextConfig"
        >{{ $t('layout.countNoNumber') }}</VText
      >
    </NuxtLinkLocale>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import VText from '~/components/ui/VText.vue'

import SegmentMixin from '~/mixins/Segment/Segment'

import { useUserStore } from '~/stores/user'

import type { PropConfigType } from '~/components/ui/VText.vue'

export default defineComponent({
  components: {
    VText,
  },
  mixins: [SegmentMixin],
  data() {
    const commonTextConfig: PropConfigType = 'sans/14/medium'

    return {
      commonTextConfig,
    }
  },
  computed: {
    ...mapState(useUserStore, { GROOVIZ: 'grooviz' }),
  },
  methods: {
    handleTrackReferralAccessSegment(): void {
      this.trackSegmentEvent('Referral Curator - Page Accessed', {
        accessed_from: 'top navbar',
      })
    },
  },
})
</script>
