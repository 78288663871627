<template>
  <div class="tw-grid tw-grid-cols-1 tw-gap-2">
    <VText v-once cfg="sans/16/medium">{{ name }}</VText>
    <div class="infos">
      <!-- N.B: component hidden for Curators for now -->
      <!-- <VText cfg="sans/14/regular" color="gray-500">
        {{
          $t('band.RPMenu.completion', { force: profileProgressionPercentage })
        }}
        ·
      </VText> -->
      <NuxtLinkLocale :to="link" @click="$emit('close')">
        <VText cfg="sans/14/medium" color="orange-500">
          {{ $t('band.RPMenu.profile') }}
        </VText>
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import VText from '~/components/ui/VText.vue'

import CuratorProfileProgression from '~/mixins/influencer/ProfileProgression'

import type Band from '~/types/band'
import type { Influencer } from '~/types/influencer'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    VText,
  },
  mixins: [CuratorProfileProgression],
  props: {
    entity: {
      type: Object as PropType<Influencer | Band>,
      required: true,
    },
    kind: {
      type: String as PropType<'band' | 'influencer'>,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    name(): string {
      const influencer = this.entity as Influencer
      return (
        influencer.entity.charAt(0).toUpperCase() + influencer.entity.slice(1)
      )
    },
    link(): string {
      return `/${this.kind}/profile/${this.entity.slug}/`
    },
  },
})
</script>
