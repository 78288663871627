<script setup lang="ts">
import { storeToRefs } from 'pinia'

import AccountSwapper from '~/components/nav/loggedIn/contextMenu/AccountSwapper.vue'
import ProfileInfos from '~/components/nav/loggedIn/contextMenu/profileInfos.vue'
import NavMobileListBand from '~/components/nav/mobile/list/band.vue'
import NavMobileListInfluencer from '~/components/nav/mobile/list/influencer.vue'
import DirectionalSwipe from '~/components/transitions/directionalSwipe.vue'
import VText from '~/components/ui/VText.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useLogout } from '~/composables/useLogout'

import { useLoginStore } from '~/stores/login'
import { useRootStore } from '~/stores/root'
import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'
import { useUserInfluencerStore } from '~/stores/userInfluencer'

import type { PropConfigType } from '~/components/ui/VText.vue'
import type Band from '~/types/band'
import type { Influencer } from '~/types/influencer'
import type { SwipeDirection } from '~/types/SwipeDirection'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const textConfig: Readonly<PropConfigType> = 'sans/14/medium'

const userInfluencerStore = useUserInfluencerStore()
const userStore = useUserStore()
const segmentTrack = useSegmentTrack()
const { logout } = useLogout()
const { KIND, IS_INF, IS_BAND } = storeToRefs(userStore)

const { REQUEST_ACCOUNT_SWAP: LOGIN_REQUEST_ACCOUNT_SWAP } = useLoginStore()
const { accounts: ACCOUNTS } = storeToRefs(useRootStore())
const { BAND_DATA: BAND } = storeToRefs(useUserBandStore())

const transitionDirection = ref<SwipeDirection>('right')
const displayAccountSwapper = ref(false)

const entity = computed<Band | Influencer | undefined>(() => {
  if (IS_BAND.value) return BAND.value
  else if (IS_INF.value) return userInfluencerStore as unknown as Influencer
  else return undefined
})

onBeforeUnmount(() => emit('close'))

function handleToWidgetClick() {
  emit('close')
  handleTrackReferralAccessSegment()
}

function handleTrackReferralAccessSegment(): void {
  segmentTrack('Referral Curator - Page Accessed', {
    accessed_from: 'dropdown_menu',
  })
}
</script>

<template>
  <div class="mobileList" :class="{ expand: displayAccountSwapper }">
    <DirectionalSwipe :direction="transitionDirection">
      <AccountSwapper
        v-if="displayAccountSwapper"
        key="accSwap"
        @close="displayAccountSwapper = false"
      />
      <div v-else class="tw-grid tw-grid-cols-1 tw-px-5 tw-py-4 sm:tw-px-0">
        <ProfileInfos
          v-if="entity && KIND"
          :kind="KIND"
          class="tw-py-3 sm:tw-px-5 sm:tw-pt-0"
          :entity="entity"
          @close="emit('close')"
        />
        <NuxtLinkLocale
          v-if="IS_INF"
          to="/influencer/list/all/"
          class="hoverText"
          @click="emit('close')"
        >
          <VText v-once :cfg="textConfig">
            {{ $t('layout.navigation12') }}
          </VText>
        </NuxtLinkLocale>

        <NavMobileListBand v-if="IS_BAND" @close="emit('close')" />
        <NavMobileListInfluencer v-if="IS_INF" @close="emit('close')" />

        <NuxtLinkLocale
          :to="`/${KIND}/parameter/`"
          class="hoverText"
          @click="emit('close')"
        >
          <VText v-once :cfg="textConfig">
            {{ $t('layout.menu.menu02') }}
          </VText>
        </NuxtLinkLocale>
        <NuxtLinkLocale
          v-if="IS_INF"
          to="/influencer/widget/"
          class="hoverText"
          @click="handleToWidgetClick"
        >
          <VText
            :cfg="textConfig"
            class="tw-grid tw-grid-cols-1 tw-gap-1"
            html-tag="div"
          >
            <!-- TODO: fix invalid html -->
            <div>{{ $t('layout.menu.receiveMoreMusic') }}</div>
            <div class="tw-text-xs tw-text-gray-500">
              {{ $t('layout.menu.shareGrooverAround') }}
            </div>
          </VText>
        </NuxtLinkLocale>
        <VText
          :cfg="textConfig"
          html-tag="a"
          href="https://help.groover.co/"
          class="hoverText"
          @click="emit('close')"
        >
          {{ $t('layout.menu.menu03') }}
        </VText>
        <VText
          v-if="ACCOUNTS.length"
          class="hoverText tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-gap-4"
          :cfg="textConfig"
          @click.stop="displayAccountSwapper = true"
        >
          <span class="otherText">{{ $t('layout.menu.menu04') }}</span>
          <i class="fas fa-chevron-right tw-text-gray-400" />
        </VText>
        <VText
          v-else
          :cfg="textConfig"
          class="hoverText tw-cursor-pointer"
          @click="LOGIN_REQUEST_ACCOUNT_SWAP()"
        >
          {{ $t('auth.accountSwitch00') }}
        </VText>
        <VText
          :cfg="textConfig"
          class="hoverText tw-cursor-pointer"
          @click="logout()"
        >
          {{ $t('layout.menu.menu05') }}
        </VText>
      </div>
    </DirectionalSwipe>
  </div>
</template>

<style lang="scss" scoped>
.mobileList .hoverText,
.mobileList :deep(.hoverText) {
  @apply tw-py-3;

  @screen sm {
    @apply tw-bg-white tw-px-5 tw-transition-colors tw-duration-150 tw-ease-in-out;

    &:hover {
      @apply tw-bg-gray-100;
    }
  }
}
</style>
