<template>
  <div class="burger" :class="{ big: open }" @click.stop="$emit('toggle')">
    <div class="topBar bar" :class="{ crossed: open }" />
    <div class="botBar bar" :class="{ crossed: open }" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggle'],
})
</script>

<style scoped>
.burger {
  position: relative;
  width: 20px;
  height: 24px;
  cursor: pointer;
  transition: 0.3s;
}

.burger.big {
  height: 24px;
}

.burger .bar {
  position: absolute;
  border-radius: 4px;
  width: 20px;
  height: 4px;
  background-color: var(--color-main);
  transition: 0.15s var(--trans-standard);
}

.burger .bar.crossed {
  width: 20px;
}

.burger .bar.topBar {
  top: calc(50% - 6px);
}

.burger .bar.topBar.crossed {
  top: calc(50% - 2px);
  transform: rotate(45deg);
}

.burger .bar.botBar {
  bottom: calc(50% - 6px);
}

.burger .bar.botBar.crossed {
  bottom: unset;
  top: calc(50% - 2px);
  transform: rotate(-45deg);
}

.burger .bar.topBar.crossed,
.burger .bar.botBar.crossed {
  background-color: var(--color-darker-grey);
}
</style>
