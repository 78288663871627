import configToQs from '~/helpers/configToQs'

import type { CoreFetch } from '~/types/globalDeclarations/CoreFetch'

export type ApiReadNotificationPostData = {
  ids?: number[]
  see_all?: boolean
}

export type ApiReadNotificationsResponse = {
  updated: number
}

export function provideReadNotifications(coreFetch: CoreFetch) {
  return async <T extends 'band' | 'influencer'>(
    { ids, see_all }: ApiReadNotificationPostData,
    bandId?: T extends 'band' ? string : undefined,
  ): Promise<ApiReadNotificationsResponse> => {
    return coreFetch.$post<ApiReadNotificationsResponse>(
      `/notification/notification/seen/?${configToQs({
        band_id: bandId,
      })}`,
      {
        see_all,
        ids,
      },
    )
  }
}
